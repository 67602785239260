import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Navbar from '../components/Navbar'

const LocationTemplate = ({
  name,
  helmet,
  location,
}) => {
  return (
    <section className="section">
      {helmet || ''}
      {console.log("LOC", location)}
      <div className="content">
        <h1 className="title is-size-2 has-text-weight-bold is-bold-light event_title_header">
          {location.name}
        </h1>
        {location.address ?
          <div>
            <h3 className="location-heading">Location</h3>
            <p>
              {location.room ? `Room: ${location.room} - ` : ''}
              {location.address} 
            </p>
          </div> : ''}
        {location.buildingEntrance ?
          <div>
            <h4 className="location-heading">Building Entrance</h4>
            <p>{location.buildingEntrance}</p>
          </div> : ''}

        {location.parking ?
          <div>
            <h4 className="location-heading">Parking</h4>
            <p>{location.parking}</p>
          </div> : ''}

        {location.publicTransit ?
          <div>
            <h4 className="location-heading">Public Transit</h4>
            <p>{location.publicTransit}</p>
          </div> : ''}

        {location.additionalNotes ?
          <div>
            <h3 className="location-heading">Other Notes</h3>
            <p>{location.additionalNotes}</p>
          </div> : ''}

        {location.search ?
          <div className="mapouter">
            <h4 className="location-heading">Map</h4>
            <div className="gmap_canvas">
              <iframe title="location-map" width="100%" className="gmap_canvas" id="gmap_canvas" src={`https://maps.google.com/maps?q=${encodeURIComponent(location.search)}&t=&z=15&ie=UTF8&iwloc=&output=embed`} frameBorder='0' scrolling="no"></iframe>
            </div>
          </div> : ''}
      </div>
    </section>
  )
}

LocationTemplate.propTypes = {
  name: PropTypes.string,
  helmet: PropTypes.object,
  address: PropTypes.string,
  room: PropTypes.string,
}

const Location = ({ data }) => {
  const { location } = data
  return (
    <Layout>
      <section className="section">
        <Navbar showBackArrow={true} />
        <LocationTemplate
          helmet={<Helmet title={`${location.name} | Location`} />}
          name={location.name}
          location={location}
        />
      </section>
    </Layout>
  )
}

Location.propTypes = {
  data: PropTypes.shape({
    fluxibleCmsLocations: PropTypes.object,
  }),
}

export default Location

export const pageQuery = graphql`
  query CmsLocationByID($id: String!) {
    location: fluxibleLocations(id: { eq: $id }) {
      id
      name
      address
      buildingEntrance
      additionalNotes
      editionId
      latitude
      longitude
      parking
      publicTransit
      room
      search
    }
  }
`
